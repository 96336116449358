.shops-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 350px;
  position: relative
}

.shops-container .shops {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 23px;
  justify-content: center;
}

.shops-container .shops .item {
  height: fit-content;
  border-radius: 12px;
  background-color: var(--grey3);
  margin: auto;
  width: 275px;
  max-width: 275px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}


.shops .item .menu-dots {
  text-align: end;
}

.item p {
  margin-top: 10px;
  font-size: 1rem;
}

.shops .item .salon-info {
  padding: 10px;
}

.shops .item .salon-name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.shops .item .salon-subheading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  line-height: 10px;
}

.shops .item .likes {
  display: flex;
  width: 70px;
  align-items: center;
  justify-content: center;
  background-color: #ff002214;
  border-radius: 20px;
  height: 40px;
  gap: 8px;
  margin-bottom: 0px;
}

.shops .item .rating {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background-color: var(--yellow);
  border-radius: 20px;
  height: 40px;
  margin-bottom: 0px;
  padding: 0px 15px;
  border: 1px solid var(--yellowHover);
}

.shops .item .shop-data-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shops .item .shop-photo {
  width: 230px;
  height: 230px;
  object-fit: cover;
  border-radius: 12px;
}

.shops .item .shop-photo:hover {
  transform: scale(1.05, 1.05);
  background-color: rgba(128, 128, 128, 0.042);
  cursor: pointer;
}

.shops-container .load-more-wrapper {
  padding: 56px;
  margin-bottom: 40px;
}

.shops-container .load-more-wrapper .ant-btn {
  background-color: var(--yellow);
  color: black;
  border: none;
  padding: 0px 16px 0px 16px;
  border-radius: 12px;
  min-width: 116px;
  font-family: Poppins, sans-serif !important;
}

.shops-container .load-more-wrapper .ant-btn:hover {
  background-color: var(--yellowHover) !important;
  color: rgb(0, 0, 0) !important;
  transform: scale(1.02, 1.02);
}

@media (max-width: 1024px) {
  .shops-container .shops .item {
    width: calc(50% - 50px);
  }
}

@media (max-width: 768px) {
  .shops-container .shops .item {
    width: calc(100% - 50px);
  }
}