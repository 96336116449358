.home {
    height: 100vh;
    overflow-y: auto;
}

.home .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
}

.home .header img {
    cursor: pointer;
}


hr {
    margin-inline: 10px;
    color: var(--grey2);
    opacity: 0.1;
}

.home .header-buttons {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.home .brown {
    background-color: var(--brown);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.home .brown:hover {
    background-color: var(--brownHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.home .yellow {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.home .yellow:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

@media (max-width: 500px) {

    .home .brown,
    .home .yellow {
        min-width: 75px !important;
    }
}

.home .container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
}


.home .main-image {
    width: 100%;
    height: auto;
    margin: auto;
    border-radius: 12px;
}

.home .discover .heading {
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin: 0;
    -webkit-text-stroke: 0.5px var(--grey2);
    width: auto;
}

@supports (-moz-appearance: none) {

    .home .discover .heading,
    .home .discover .description {
        -webkit-text-stroke: unset;
        -moz-text-stroke: 0.5px var(--grey2);
        text-shadow: 0.5px 0.5px 0 var(--grey2);
    }
}

.home .discover .description {
    font-size: 20px;
    font-weight: 400;
    color: #464646;
    line-height: 18px;
    text-align: center;
    -webkit-text-stroke: 0.5px var(--grey2);
}

.home .options {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.home .button-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

@media (max-width:648px) {
    .home .button-container {
        flex-direction: column;
    }
}

.home .purple {
    background-color: var(--purple);
    color: white;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.home .purple:hover {
    background-color: var(--purpleHover) !important;
    color: white !important;
    transform: scale(1.02, 1.02);
}

.home .bottom-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #dddddddd;
    height: 80px;
    padding: 5px 30px;
    font-weight: 600;
}

.home .bottom-tab-message {
    display: flex;
    align-items: center;
    gap: 5px;
}

.home .user-initials {
    background-color: var(--purple-background);
    padding: 10px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--purpleHover);
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}