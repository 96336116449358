.contact-us {
    height: 100vh;
    overflow-y: auto;
}

.contact-us .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
}

.contact-us .header img {
    cursor: pointer;
}


hr {
    margin-inline: 10px;
    color: var(--grey2);
    opacity: 0.1;
}

.contact-us .header-buttons {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.contact-us .brown {
    background-color: var(--brown);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.contact-us .brown:hover {
    background-color: var(--brownHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.contact-us .yellow {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.contact-us .yellow:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

@media (max-width: 500px) {

    .contact-us .brown,
    .contact-us .yellow {
        min-width: 75px !important;
    }
}

.contact-us .container {
    width: 90%;
    margin: 0px auto;
    min-height: calc(100vh - 360px);
}

.contact-us .heading {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.contact-us .intro {
    font-size: 18px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px auto;
}

.contact-us .socials {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin: 25px 0;
}

.contact-us .social-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.157);
    padding: 10px;
    border-radius: 15px;
}

.contact-us .social-container:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact-us .guideline {
    font-size: 18px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px auto;
    color: grey;
}

.contact-us .social-name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}