.privacy-policy {
    height: 100vh;
    overflow-y: auto;
}

.privacy-policy .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
}

.privacy-policy .header img {
    cursor: pointer;
}


hr {
    margin-inline: 10px;
    color: var(--grey2);
    opacity: 0.1;
}

.privacy-policy .header-buttons {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.privacy-policy .brown {
    background-color: var(--brown);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.privacy-policy .brown:hover {
    background-color: var(--brownHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.privacy-policy .yellow {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.privacy-policy .yellow:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

@media (max-width: 500px) {

    .privacy-policy .brown,
    .privacy-policy .yellow {
        min-width: 75px !important;
    }
}

.privacy-policy .container {
    width: 90%;
    margin: 0px auto;
    min-height: calc(100vh - 360px);
}

.privacy-policy .heading {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.privacy-policy .intro {
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0px auto;
}

.privacy-policy .data-container {
    margin-block: 30px;
}

.privacy-policy .data-container p {
    margin-block: 0;
}

.privacy-policy .data-container .title {
    font-size: 15px;
    font-weight: 600;
}

.privacy-policy .data-container .title p {
    margin-block-end: 5px;
}

.privacy-policy .data-container .text {
    font-size: 15px;
    font-weight: 300;
    width: 95%;
    margin: auto;
}

.privacy-policy .data-container .text p {
    margin-block-end: 10px;
}

.privacy-policy .data-container .point {
    font-size: 15px;
    font-weight: 300;
    width: 85%;
    margin: auto;
}

.privacy-policy .data-container .point p {
    margin-block-end: 10px;
}