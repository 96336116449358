.staff {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.staff .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.staff .add-button {
    background-color: var(--yellow);
    color: black;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
    border: 0;
    cursor: pointer;
}

.staff .add-button:hover {
    background-color: var(--yellowHover) !important;
    color: black !important;
    transform: scale(1.02, 1.02);
}

.staff .heading {
    font-weight: 500;
    font-size: 18px;
}