.general .preference .selection-item {
    width: 30%;
}

.general .preference .selection {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.general .preference .selection button {
    width: 25px;
    cursor: pointer;
}