.signin {
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signin .language-selector {
  margin-left: auto;
}

.signin .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
}

.signin .container img {
  cursor: pointer;
}

.signin .heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 10px;
  margin-bottom: 0;
  margin-top: 40px;
}

.signin .submit-button {
  width: 100%;
  background-color: var(--yellow);
  color: black;
  padding: 0px 16px 0px 16px;
  border-radius: 12px;
  min-width: 116px;
  font-family: Poppins, sans-serif !important;
  cursor: pointer;
}

.signin .submit-button:hover {
  width: 100%;
  background-color: var(--yellowHover) !important;
  color: var(--black) !important;
  transform: scale(1.02, 1.02);
}

.signin .message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.signin .footer {
  text-align: center;
  padding: 0px 20px;
  color: var(--grey);
  margin-top: 15px;
}

.signin .j-space-between {
  justify-content: space-between;
}