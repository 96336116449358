.forum-post {
    height: 100vh;
    overflow-y: auto;
}

.forum-post .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
}

.forum-post .header img {
    cursor: pointer;
}


hr {
    margin-inline: 10px;
    color: var(--grey2);
    opacity: 0.1;
}

.forum-post .header-buttons {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.forum-post .brown {
    background-color: var(--brown);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.forum-post .brown:hover {
    background-color: var(--brownHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.forum-post .yellow {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.forum-post .yellow:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

@media (max-width: 500px) {

    .forum-post .brown,
    .forum-post .yellow {
        min-width: 75px !important;
    }
}

.forum-post .heading {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.forum-post .container {
    min-height: calc(100vh - 360px);
}

.forum-post .user-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 20px auto;
}

.forum-post .user-details .user-data-container {
    display: flex;
    gap: 15px;
}

.forum-post .user-data-container .user-data {
    display: flex;
    align-items: center;
    gap: 5px;
}

.forum-post .user-details .profile {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.forum-post .user-details .profile img {
    border: 1px solid grey;
    border-radius: 50px;
    height: 75px;
    width: 75px;
    cursor: pointer;
}

.forum-post .user-details .profile:hover,
.forum-post .comments .profile-2:hover,
.forum-post .comments .profile:hover,
.forum-post .user-details .user-name:hover,
.forum-post .back-icon:hover {
    transform: scale(1.02);
}

.forum-post .user-details .post-date {
    font-size: 14px;
    color: var(--grey2);
}

.forum-post .user-details .user-name {
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
}

.forum-post .user-details .actions {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    font-size: 13px;
}

.forum-post .user-details .votes {
    padding: 6px 12px;
    border-radius: 8px;
    width: 50px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.forum-post .user-details .action-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.forum-post .user-details .actions .action-button {
    padding: 3px;
    border-radius: 15px;
    border: 1px solid var(--grey2);
}

.forum-post .user-details .actions .action-button-selected {
    padding: 3px;
    border-radius: 15px;
    border: 1px solid var(--grey2);
    background-color: #78589F;
    color: #d4cddd;
}

.forum-post .user-details .actions .action-button:hover,
.forum-post .user-details .actions .action-button-selected:hover {
    background-color: #a086c1;
    cursor: pointer;
    color: #d4cddd;
}

.forum-post .user-details .actions .action-button:active,
.forum-post .user-details .actions .action-button-selected:active {
    transform: scale(1.05);
}

.forum-post .user-details .actions .action-button .action-button-icon,
.forum-post .user-details .actions .action-button-selected .action-button-icon {
    display: flex;
    align-items: center;
}


.forum-post .post-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
    margin: 30px auto;
    width: 85%;
    max-width: 900px;
    padding: 15px;
}

.forum-post .post-card .description {
    width: 85%;
    margin: auto;
    overflow: hidden;
    word-break: break-word;
}

.forum-post .comments {
    width: 80%;
    max-width: 800px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.forum-post .comment {
    border: 1px solid var(--grey3);
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
    border-radius: 15px;
}

.forum-post .comment .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.forum-post .comment .profile,
.forum-post .comment .profile-2 {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding: 5px;
}

.forum-post .user-initials {
    background-color: var(--purple-background);
    padding: 10px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}

.forum-post .comment .profile img {
    border: 1px solid grey;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.forum-post .user-initials-2 {
    background-color: var(--purple-background);
    padding: 10px;
    border-radius: 25px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}

.forum-post .comment .profile-2 img {
    border: 1px solid grey;
    border-radius: 45px;
    height: 45px;
    width: 45px;
    cursor: pointer;
}

.forum-post .comment .name {
    font-size: 15px;
}

.forum-post .comment .post-time {
    font-size: 12px;
    color: var(--grey2);
}

.forum-post .comment p {
    margin: 0px;
}

.forum-post .comment .comment-user-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    word-break: break-word;
}

.forum-post .comment .name {
    font-weight: 500;
}

.forum-post .back-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.forum-post .add-comment {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 25px auto;
    max-width: 800px;
}

.forum-post .add-comment button {
    background-color: #78589F;
    color: white;
}

.forum-post .add-comment button:hover {
    background-color: #a086c1 !important;
    color: white !important;
    border: none;
}

.forum-post .add-comment p {
    font-weight: 500;
}

.forum-post .add-comment .text-area {
    height: 150px;
}

.forum-post .comment .actions {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    font-size: 13px;
    margin-left: auto;
}

.forum-post .comment .votes {
    padding: 6px 12px;
    border-radius: 8px;
    width: 50px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.forum-post .comment .action-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.forum-post .comment .actions .action-button {
    padding: 3px;
    border-radius: 15px;
    border: 1px solid var(--grey2);
}

.forum-post .comment .actions .action-button-selected {
    padding: 3px;
    border-radius: 15px;
    border: 1px solid var(--grey2);
    background-color: #78589F;
    color: #d4cddd;
}

.forum-post .comment .actions .action-button:hover,
.forum-post .comment .actions .action-button-selected:hover {
    background-color: #a086c1;
    cursor: pointer;
    color: #d4cddd;
}

.forum-post .comment .actions .action-button:active,
.forum-post .comment .actions .action-button-selected:active {
    transform: scale(1.05);
}

.forum-post .comment .actions .action-button .action-button-icon,
.forum-post .comment .actions .action-button-selected .action-button-icon {
    display: flex;
    align-items: center;
}


.forum-post .load-more-wrapper {
    padding: 56px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forum-post .load-more-wrapper .ant-btn {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.forum-post .load-more-wrapper .ant-btn:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.forum-post .no-posts {
    margin: 30px 0px;
    font-weight: 600;
    font-size: 25px;
    color: var(--grey2);
}

.forum-post .no-comments {
    margin: 30px 0px;
    font-weight: 600;
    font-size: 25px;
    color: var(--grey2);
    text-align: center;
}

.forum-post .forum-text-container {
    display: flex;
    justify-content: space-between;
}

.forum-post .forum-text {
    width: 95%;
}