.forum {
    height: 100vh;
    overflow-y: auto;
}

.forum .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
}

.forum .header img {
    cursor: pointer;
}

hr {
    margin-inline: 10px;
    color: var(--grey2);
    opacity: 0.1;
}

.forum .header-buttons {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.forum .brown {
    background-color: var(--brown);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.forum .brown:hover {
    background-color: var(--brownHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.forum .yellow {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.forum .yellow:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

@media (max-width: 500px) {

    .forum .brown,
    .forum .yellow {
        min-width: 75px !important;
    }
}

.forum .heading-container {
    max-width: 1200px;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-around;
}

.forum .heading {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

@media (max-width:460px) {
    .forum .heading {
        font-size: 23px;
    }
}

.forum .container {
    width: 90%;
    margin: 0px auto;
    min-height: calc(100vh - 360px);
}

.forum .container .posts {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: 40px;
}

.forum .searchbar {
    margin: auto;
    max-width: 750px;
    width: 90%;
}

.forum .post-card {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--grey3);
    max-width: 1000px;
    width: 90%;
    display: flex;
    gap: 10px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
    cursor: pointer;
}

.forum .post-card:hover {
    transform: scale(1.01);
    background-color: #a086c110;
}

.forum .post-card .profile {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.forum .post-card .profile:hover {
    transform: scale(1.05);
}

.forum .post-card .profile img {
    border: 1px solid grey;
    border-radius: 30px;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.forum .user-initials {
    background-color: var(--purple-background);
    padding: 10px;
    border-radius: 30px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}

.forum .post-card .data-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 55px);
}

.forum .post-card .body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.forum .post-card .title {
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
}

.forum .post-card .data {
    font-size: 13px;
}

.forum .post-card .data .clickable-text {
    text-decoration: underline;
    font-weight: 500;
}

.forum .post-card .data .clickable-text:hover {
    transform: scale(1.02);
    font-weight: 600;
}

.forum .post-card .actions {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    font-size: 13px;
}

.forum .post-card .votes {
    padding: 6px 12px;
    border-radius: 8px;
    width: 50px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.forum .post-card .action-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.forum .post-card .actions .action-button {
    padding: 3px;
    border-radius: 15px;
    border: 1px solid var(--grey2);
}

.forum .post-card .actions .action-button-selected {
    padding: 3px;
    border-radius: 15px;
    border: 1px solid var(--grey2);
    background-color: #78589F;
    color: #d4cddd;
}

.forum .post-card .actions .action-button:hover,
.forum .post-card .actions .action-button-selected:hover {
    background-color: #a086c1;
    cursor: pointer;
    color: #d4cddd;
}

.forum .post-card .actions .action-button:active,
.forum .post-card .actions .action-button-selected:active {
    transform: scale(1.05);
}

.forum .post-card .actions .action-button .action-button-icon,
.forum .post-card .actions .action-button-selected .action-button-icon {
    display: flex;
    align-items: center;
}

.forum .post-card .action-details .details {
    font-size: 12px;
    color: var(--grey);
}

.forum .filter-button {
    display: flex;
    align-items: center;
    background-color: #78589F;
    background: #78589F;
    color: #d4cddd;
    border: none;
    width: max-content;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
}

.forum .filter-button:hover,
.ok-button:hover,
.cancel-button:hover {
    background-color: #a086c1 !important;
    color: #d4cddd !important;
    border: 1px solid #a086c1 !important;
}

.ok-button {
    background-color: #78589F;
    background: #78589F;
    color: #d4cddd;
    border: none;
}

.forum .load-more-wrapper {
    padding: 56px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forum .load-more-wrapper .ant-btn {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.forum .load-more-wrapper .ant-btn:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.forum .no-posts {
    margin: 30px 0px;
    font-weight: 600;
    font-size: 25px;
    color: var(--grey2);
    text-align: center;
}