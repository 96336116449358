.shop-account-settings {
  padding: 40px 10px;
}

.shop-details {
  display: flex;
  gap: 24px;
  margin-bottom: 40px;
}

@media screen and (max-width: 650px) {
  .shop-account-settings {
    padding: 10px 20px;
  }
}

.shop-account-settings .left-container {
  flex: 1;
}

.shop-account-settings .left-container .section-title {
  color: #030303;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.shop-account-settings .left-container .section-info {
  color: #030303;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.shop-account-settings .right-container {
  flex: 3;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 80%;
}

.shop-account-settings .right-container .form-container {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  border-radius: 5px 5px 0 0;
  position: relative;
  width: 100%;
}

.shop-account-settings .right-container .form-container .form-title {
  color: #030303;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.shop-account-settings .right-container .form-container .form-subtitle {
  color: #030303;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
}

.shop-account-settings .right-container .form-container .profile-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 15px 0 24px 0;
}

.shop-account-settings .profile-info .profile-txt {
  color: #030303;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.shop-account-settings .profile-info img {
  height: 50px;
  width: 50px;
  border-radius: 25px;
}

.shop-account-settings .form-container .form-row {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 20px;
}

.shop-account-settings .right-container .form-container .ant-form-item {
  color: #030303;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.shop-account-settings .right-container .form-container .form-row .ant-form-item {
  display: inline-block;
  width: 50%;
}

@media screen and (max-width: 500px) {
  .shop-account-settings .form-container .form-row {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }

  .shop-account-settings .right-container .form-container .form-row .ant-form-item {
    display: inline-block;
    width: 100%;
  }
}

.shop-account-settings .right-container .form-footer {
  display: flex;
  background-color: #F8F8F8;
  padding: 12px 24px;
  justify-content: right;
  border-radius: 0 0 5px 5px;
}

.shop-account-settings .right-container .form-footer .ant-btn:hover {
  color: #030303 !important;
}

.photos-and-tags {
  display: flex;
  margin-top: 40px;
  gap: 24px;
  margin-bottom: 40px;
}

.services {
  display: flex;
  margin-top: 40px;
  gap: 24px;
}

@media screen and (max-width: 650px) {

  .photos-and-tags,
  .services,
  .shop-account-settings .shop-details {
    flex-direction: column;
    margin: auto;
    align-items: center;
  }
}

.photos-and-tags .right-container .form-container .form-title {
  margin-bottom: 16px;
}

.photos-and-tags .upload-photos {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 35px;
}

.photos-and-tags .upload-photos .big-upload {
  width: 50%;
  margin-bottom: 15px;
}

.photos-and-tags .upload-photos .big-upload .ant-upload-list-item,
.photos-and-tags .upload-photos .big-upload .ant-upload {
  height: 200px !important;
  width: 250px;
}

@media (max-width: 860px) {

  .photos-and-tags .upload-photos .big-upload .ant-upload-list-item,
  .photos-and-tags .upload-photos .big-upload .ant-upload {
    width: 100%;
    max-width: 225px;
  }
}

@media (min-width: 861px) and (max-width: 991px) {

  .photos-and-tags .upload-photos .big-upload .ant-upload-list-item,
  .photos-and-tags .upload-photos .big-upload .ant-upload {
    max-width: 250px;
  }
}

@media (max-width: 1175px) and (min-width: 992px) {

  .photos-and-tags .upload-photos .big-upload .ant-upload-list-item,
  .photos-and-tags .upload-photos .big-upload .ant-upload {
    max-width: 225px;
  }
}

.photos-and-tags .upload-photos .small-uploads {
  width: 50%;
}

@media screen and (max-width: 700px) {
  .photos-and-tags .upload-photos {
    flex-direction: column;
  }

  .photos-and-tags .upload-photos .big-upload {
    width: 100%;
  }

  .photos-and-tags .upload-photos .small-uploads {
    width: 100%;
  }
}

.photos-and-tags .upload-photos .ant-upload-text {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.photos-and-tags .upload-photos .ant-upload-text .extra {
  color: #4B5563;
  font-weight: 400;
}

.photos-and-tags .upload-photos .ant-upload-hint {
  margin: 0;
}

.photos-and-tags .upload-photos .small-uploads {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.photos-and-tags .upload-photos .small-uploads .small-uploads-row {
  display: flex;
  gap: 10px;
}

.photos-and-tags .upload-photos .small-uploads .small-uploads-row .small-upload-btn {
  width: 50%;
}

.photos-and-tags .add-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  padding: 12px;
  border-radius: 5px;
  gap: 15px;
  border: 1px #D1D5DB solid;
}

.photos-and-tags .tag {
  height: fit-content;
  width: fit-content;
  background-color: white;
  border: 1px solid black;
  line-height: 2px;
  border-radius: 30px;
  padding: 0px 12px;
  margin: 10px;
}

.shop-account-settings .form-container {
  min-height: 423px;
}

.shop-account-settings .form-container.passwords {
  min-height: 300px;
}

@media (max-width: 500px) {
  .shop-account-settings .form-container {
    min-height: 507px;
  }

  .shop-account-settings .form-container.passwords {
    min-height: 307px;
  }
}

.shop-account-settings .phone-input input {
  height: 30px;
  border: 1px solid #d9d9d9;
  padding: 0px 11px;
  border-radius: 6px;
  width: 100% !important;
}

.shop-account-settings .phone-input input:focus,
.shop-account-settings .phone-input input:active {
  border-color: #4096ff;
  outline: none;
}

.shop-account-settings .services-list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
}

.shop-account-settings .services-container {
  width: 95%;
  margin: 25px auto;
}

.shop-account-settings .service-card {
  background-color: #fafafafa;
  border: 1px solid rgb(236, 236, 236);
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  width: 325px;
}

.shop-account-settings .service-card img {
  border-radius: 0;
  height: 325px;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 3px, rgba(0, 0, 0, 0.03) 0px 1px 2px;
}

.shop-account-settings .service-card .info {
  display: flex;
  gap: 5px;
  align-items: center;
}

.shop-account-settings .service-card .detail {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  color: var(--grey2);
}

.shop-account-settings .review-button {
  background-color: #E26274;
  margin-block-start: 10px;
  border-radius: 12px;
  align-self: center;
  width: 100%;
  cursor: pointer;
  font-family: Poppins, sans-serif !important;
}

.shop-account-settings .review-button:hover {
  background-color: #cd3549 !important;
  border: none;
  color: black !important;
}

.shop-account-settings .no-service-data {
  height: 150px;
  font-weight: 500;
  font-size: 20px;
  color: var(--grey2);
  display: flex;
  justify-content: center;
  align-items: center;
}