.preference {
    display: flex;
    padding: 50px 20px;
    gap: 20px;
    background-color: white;
}

.preference hr {
    background-color: white;
}

.preference .heading {
    font-weight: 500;
    font-size: 18px;
}

.preference .subheading {
    font-weight: 500;
    font-size: 14px;
}

.preference .text {
    font-size: 14px;
    font-weight: 300;
}

.preference .text-section {
    width: 35%;
}

.preference .selection {
    width: 65%;
    border: 1px solid var(--grey3);
    padding: 20px;
    text-align: center;
}

.preference .selection-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 70%;
}

.preference .selection-item .ant-select-selector {
    width: 100px;
}

.preference .submit-button {
    background-color: var(--yellow);
    color: black;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
    border: 0;
    cursor: pointer;
}

.preference .submit-button:hover {
    background-color: var(--yellowHover) !important;
    color: black !important;
    transform: scale(1.02, 1.02);
}