.shop {
  height: 100%;
  font-family: Poppins, sans-serif !important;
}

.shop .shop-body {
  display: flex;
  flex-direction: column;
  gap: 25px;
  min-height: calc(100vh - 375px);
}

.shop .images {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
}

.shop img {
  border-radius: 50px;
}

.shop .main-image {
  flex: 1;
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

.shop .main-image img {
  object-fit: cover;
  height: 400px;
}

.shop .images .image-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.image-container .img-wrap {
  width: calc(48%);
}

.image-container .img-wrap img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .shop .images {
    flex-direction: column;
  }
}

.shop .text-body-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

.shop .text-body-container .stat-info {
  display: flex;
  gap: 10px;
  margin-block-end: 20px;
}

.shop .shop-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop .shop-details {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.shop .heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
}

.shop .text {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.shop .textheading {
  font-size: 16px;
  font-weight: 400;
  line-height: 10px;
  margin-inline-start: 10px;
}

.shop .subheading {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

@media (max-width: 550px) {
  .shop .subheading {
    width: 105px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.shop .subtext {
  font-size: 12px;
  font-weight: 300;
  line-height: 2px;
}

.shop .rating {
  background-color: var(--yellow);
  width: 19px;
  border-radius: 20px;
  padding: 5px 9px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 2px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  height: fit-content;
  margin: auto;
}

.shop .specifications {
  margin-inline-start: 15px;
}

.shop .review-button {
  background-color: #e26274;
  margin-block-start: 10px;
  border-radius: 12px;
  align-self: center;
  width: 100%;
  cursor: pointer;
  font-family: Poppins, sans-serif !important;
}

.shop .review-button:hover {
  background-color: #cd3549 !important;
  border: none;
  color: black !important;
}

.shop .load-button {
  background-color: var(--yellow);
  border: none;
  border-radius: 12px;
  align-self: center;
  min-width: 100px;
  cursor: pointer;
  font-family: Poppins, sans-serif !important;
}

.shop .load-button:hover {
  background-color: var(--yellowHover) !important;
  border: none;
  color: black !important;
}

.shop .tabs-container {
  width: 90%;
  margin: auto;
}

.shop .tab-item {
  font-weight: 600;
  color: var(--grey);
  text-align: center;
  margin-block: 30px;
}

.shop .services-list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
}

.shop .services {
  background-color: var(--grey3);
  width: 95%;
  margin: auto;
}

.shop .services-container {
  width: 95%;
  margin: 25px auto;
}

.shop .service-card {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  width: 325px;
}

.shop .service-card img {
  border-radius: 0;
  height: 325px;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 3px, rgba(0, 0, 0, 0.03) 0px 1px 2px;
}

.shop .service-card .info {
  display: flex;
  gap: 5px;
  align-items: center;
}

.shop .service-card .detail {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  color: var(--grey2);
}

.shop .reviews {
  width: 90%;
  margin: auto;
}

.shop .reviews .review-container {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  height: 550px;
}

.shop .reviews .ratings {
  width: 35%;
}

.shop .reviews .comments {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

@media (max-width: 450px) {
  .shop .reviews .review-container {
    flex-direction: column;
    height: auto;
  }

  .shop .reviews .ratings {
    width: 100%;
  }

  .shop .reviews .comments {
    width: 100%;
    min-height: 200px;
  }
}

.shop .reviews .rating {
  font-size: 34px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  background-color: transparent;
}

.shop .reviews .comment-container {
  display: flex;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 3px, rgba(0, 0, 0, 0.03) 0px 1px 2px;
  border-radius: 12px;
  padding: 0px 12px;
  width: 90%;
  margin: auto;
}

.shop .reviews .comment-container .image-container {
  margin-top: 15px;
}

.shop .reviews .comment-container .image-container img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 32px;
}

.shop .reviews .comment-container .data-container {
  width: 100%;
}

.shop .reviews .comment-container .name-text {
  display: flex;
  gap: 10px;
  color: #00000073;
  font-size: 12px;
  font-weight: 400;
  justify-content: space-between;
}

.shop .date {
  font-size: 10px;
  margin: 0px;
}

.shop .reviews .comment-container .name-text .rating-value {
  background-color: var(--yellow);
  width: 19px;
  border-radius: 20px;
  padding: 5px 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  color: black;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  height: fit-content;
}

.shop .reviews .comment-container .name-text .time {
  color: #bfbfbf;
}

.shop .reviews .comment-container .comment-body {
  font-weight: 400;
  font-size: 14px;
}

.shop .reviews .comment-container .actions {
  display: flex;
  gap: 12px;
  color: #00000073;
  line-height: 5px;
  align-items: center;
}

.shop .tag {
  height: fit-content;
  width: fit-content;
  background-color: white;
  border: 1px solid black;
  line-height: 2px;
  border-radius: 30px;
  padding: 0px 12px;
  margin: 10px;
}

.shop .perk-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.shop .rating-types {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  line-height: 10px;
  font-weight: 500;
  color: var(--grey);
}

.shop .navigator {
  cursor: pointer;
}

.social-icon {
  font-size: 25px;
  margin-block: 5px;
}

.shop .review-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.shop .review-images img {
  height: 75px;
  width: 75px;
}

.shop .visit-information {
  font-size: 12px;
}