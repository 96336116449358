@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --yellow: #FAEF7C;
  --yellowHover: #fbec48;
  --purple: #78589F;
  --purple-background: #d3c3e6;
  --purpleHover: #6c4e90;
  --brown: #E3CCB2;
  --brownHover: #c0a382;
  --grey: #6B7280;
  --grey2: rgb(168, 165, 165);
  --grey3: #F0F0F0;
  --black: #000;
}

body {
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background: #cccccccc;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}