.navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px;
}

.navbar .body {
  height: 90px;
  display: flex;
  align-items: center;
  margin-inline: 20px;
}

.navbar hr {
  margin-inline: 10px;
  color: var(--grey2);
  opacity: 0.1;
}

.navbar .header-buttons {
  display: flex;
  gap: 10px;
}

.navbar .brown {
  background-color: var(--brown);
  color: black;
  border: none;
  padding: 0px 16px 0px 16px;
  border-radius: 12px;
  min-width: 116px;
  font-family: Poppins, sans-serif !important;
}

.navbar .brown:hover {
  background-color: var(--brownHover) !important;
  color: rgb(0, 0, 0) !important;
  transform: scale(1.02, 1.02);
}

.navbar .yellow {
  background-color: var(--yellow);
  color: black;
  border: none;
  padding: 0px 16px 0px 16px;
  border-radius: 12px;
  min-width: 116px;
  font-family: Poppins, sans-serif !important;
}

.navbar .yellow:hover {
  background-color: var(--yellowHover) !important;
  color: rgb(0, 0, 0) !important;
  transform: scale(1.02, 1.02);
}

@media (max-width: 500px) {

  .navbar .brown,
  .navbar .yellow {
    min-width: 75px;
  }
}

.navbar .back-container {
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  gap: 15px;
}

.navbar .user-initials {
  background-color: var(--purple-background);
  padding: 10px;
  border-radius: 30px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--purpleHover);
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
}

.profile-photo {
  display: flex;
  align-items: center;
  justify-content: center;

}

.profile-photo img {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  cursor: pointer;
  border: 3px solid var(--purpleHover);
}

.user-info-language {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

@media (max-width: 475px) {
  .user-info-language {
    flex-direction: column-reverse;
    gap: 5px;
  }
}

.user-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}