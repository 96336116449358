.sider .ant-menu-item {
  color: black;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.sider .ant-menu-item-selected,
.sider .ant-menu-item:hover {
  background-color: #E26274 !important;
  color: black;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.full-screen-overlay {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}