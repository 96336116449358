.like {
    /* width: 15px;
    height: 15px; */
    background-color: #F0F0F0;
    padding: 5px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black);
    cursor: pointer;
    color: #db959569;
}

.like:hover {
    transform: scale(1.05);
}

.like:active {
    transform: scale(1.10);
}

.selected-like-button {
    color: rgb(215, 0, 0);
}