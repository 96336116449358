.profile {
    height: 100%;
    line-height: 16px;
}

.profile .customer-body {
    width: 90%;
    margin: auto;
}

.profile .customer-details {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px
}

.profile .customer-details .image-container {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 100px;
}

.profile .customer-details .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile .heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
}

.profile .customer-details .text {
    font-size: 14px;
    font-weight: 300;
    text-align: left;
}

.profile .recent {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 25px;
}

.profile .recent .recent-activities {
    width: 50%;
}

.profile .recent .review-container {
    display: flex;
    gap: 20px;
    width: 90%;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 3px 2px 3px;
    background-color: #f5f5f537;
    margin: auto;
}

.profile .recent .data-container {
    height: 500px;
    overflow-y: auto;
}

.profile .recent .recent-reviews {
    width: 100%;
}

@media (max-width: 800px) {
    .profile .recent {
        flex-direction: column;
    }

    .profile .recent .review-container,
    .profile .recent .recent-activities,
    .profile .recent .recent-reviews {
        width: 100%;
    }

    .profile .empty-box {
        margin: auto;
    }

    .profile .activity-list {
        width: max-content;
        margin: auto;
    }
}

.profile .recent .recent-reviews .image-container {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 12px;
    margin-top: 15px;
}

.profile .recent .recent-reviews .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}

.profile .recent .recent-reviews .name-text {
    display: flex;
    gap: 10px;
    color: #00000073;
    font-size: 12px;
    font-weight: 400;
    line-height: 5px;
}

.profile .recent .recent-reviews .name-text .time {
    color: #BFBFBF;
}

.profile .recent .recent-reviews .review-body {
    font-weight: 400;
    font-size: 14px;
}

.profile .recent .recent-reviews .actions {
    display: flex;
    gap: 12px;
    color: #00000073;
    line-height: 5px;
}

.profile .recent .favourites .data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin: auto;
}

.profile .recent .favourites .image-container {
    width: 200px;
    height: 200px;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.profile .recent .favourites .image-container img {
    height: 100%;
}

.profile .item {
    padding: 10px;
    width: max-content;
    height: 350px;
    border-radius: 12px;
    background-color: var(--grey3);
    align-items: center;
}

.profile .item .actions-menu-container {
    position: relative;
}

.profile .item .actions-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    margin-top: 10px;
    width: 100%;
    align-self: center;
}

.profile .item .actions-menu .rating-container {
    width: 50%;
    padding-inline: 10px;
}

.profile .item .actions-menu .rating {
    background-color: var(--yellow);
    width: fit-content;
    border-radius: 12px;
    padding: 0px 12px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.profile .item .actions-menu .like-container {
    width: 50%;
    display: flex;
    padding-inline: 10px;
    justify-content: end;
}

.profile .activity-list {
    gap: 200px;
    width: fit-content;
    padding-inline-end: 50px;
    height: 750px;
    overflow-y: auto;
}

.profile .activity-item {
    display: flex;
    align-items: center;
    gap: 15px;
}

.profile .date {
    margin-inline-start: 30px;
    margin-top: 0px;
    font-size: 12px;
    font-weight: 300;
    color: #000000D9;
}

.profile .empty-box {
    color: var(--grey2);
    font-weight: 500;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 200px;
    width: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin: auto;
}

.profile .no-data-wrapper {
    background-color: #f5f5f56f;
    width: 100%;
    text-align: center;
}

.profile .no-data-wrapper-2 {
    height: calc(100vh - 375px);
    background-color: #f5f5f56f;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
}