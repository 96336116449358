.favourites {
    display: flex;
    gap: 20px;
    height: 100%;
    padding: 10px;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
}

.favourites-container {
    height: 100%
}

.favourites hr {
    background-color: white;
}

.favourites .heading {
    font-weight: 500;
    font-size: 18px;
}

.favourites .subheading {
    font-weight: 500;
    font-size: 14px;
    font-family: Poppins;
}

.favourites .text {
    font-size: 14px;
    font-weight: 300;
}

.favourites .text-section {
    width: 35%;
}

.favourites .selection {
    width: 90%;
    margin: auto;
    border: 1px solid var(--grey3);
    padding: 20px;
}

.favourites .selection-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 70%;
}

.favourites .selection-item .ant-select-selector {
    width: 100px;
}

.favourites .submit-button {
    background-color: var(--yellow);
    color: black;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
    border: 0;
    cursor: pointer;
}

.favourites .submit-button:hover {
    width: 100%;
    background-color: var(--yellowHover) !important;
    color: black !important;
    transform: scale(1.02, 1.02);
}

.favourites .recent .favourites-body {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.favourites .item {
    padding: 10px;
    width: max-content;
    height: 350px;
    border-radius: 12px;
    background-color: var(--grey3);
    align-items: center;
}

.favourites .item .actions-menu-container {
    position: relative;
}

.favourites .item .actions-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    margin-top: 10px;
    width: 100%;
    align-self: center;
}

.favourites .item .actions-menu .rating-container {
    width: 50%;
    padding-inline: 10px;
}

.favourites .item .actions-menu .rating {
    background-color: var(--yellow);
    width: fit-content;
    border-radius: 12px;
    padding: 0px 12px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.05);
}