.faq {
    height: 100vh;
    overflow-y: auto;
}

.faq .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
}

.faq .header img {
    cursor: pointer;
}


hr {
    margin-inline: 10px;
    color: var(--grey2);
    opacity: 0.1;
}

.faq .header-buttons {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.faq .brown {
    background-color: var(--brown);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.faq .brown:hover {
    background-color: var(--brownHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

.faq .yellow {
    background-color: var(--yellow);
    color: black;
    border: none;
    padding: 0px 16px 0px 16px;
    border-radius: 12px;
    min-width: 116px;
    font-family: Poppins, sans-serif !important;
}

.faq .yellow:hover {
    background-color: var(--yellowHover) !important;
    color: rgb(0, 0, 0) !important;
    transform: scale(1.02, 1.02);
}

@media (max-width: 500px) {

    .faq .brown,
    .faq .yellow {
        min-width: 75px !important;
    }
}

.faq .container {
    width: 90%;
    margin: 0px auto;
    min-height: calc(100vh - 360px);
}

.faq .heading {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    margin: 35px auto;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.faq .intro {
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px auto;
}

.faq .data-container {
    margin-block-end: 25px;
}

.faq .data-container p {
    margin-block: 0;
}

.faq .data-container .question {
    font-size: 15px;
    font-weight: 600;
}

.faq .data-container .question p {
    margin-block-end: 5px;
}

.faq .data-container .answer {
    font-size: 15px;
    font-weight: 300;
}

.faq .data-container .answer p {
    margin-block-end: 10px;
}