.sider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06)
}

.ant-menu .ant-menu-root {
    border-inline-end: 0px;
}

.ant-drawer-body {
    padding: 0 !important;
}

.ant-menu-item .ant-menu-item-active .ant-menu-item-selected .ant-menu-item-only-child {
    background-color: #E26274 !important;
    border-radius: 12px;
    align-self: center;
}

.search .list .service-button:hover {
    background-color: #cd3549;
    border: none;
    color: black;
}

.account-settings .user-initials {
    height: 50px;
    width: 50px;
}