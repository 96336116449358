.shop-list {
  width: 100%;
  overflow: hidden;
}

.loader-container {
  position: relative;
  height: 282px;
  width: 100%;
  background-color: #f5f5f56f;
}

.shop-list .heading {
  display: flex;
  align-items: center;
}

.shop-list .list {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #f5f5f56f;
}

.shop-list::-webkit-scrollbar {
  width: 0;
}

.shop-list .heading {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 5px;
  font-size: 20px;
}

.list-container {
  overflow-x: hidden;
  white-space: nowrap;
  padding-bottom: 20px;
}

.list {
  display: inline-flex;
  align-items: center;
}

.shop-list .item {
  padding: 10px;
  margin-right: 20px;
  width: max-content;
  height: 350px;
  border-radius: 12px;
  background-color: var(--grey3);
  align-items: center;
}

.item .actions-menu-container {
  position: relative;
}

.item .actions-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  margin-top: 10px;
  width: 100%;
  z-index: 999;
}

.item .actions-menu .rating-container {
  width: 50%;
  padding-inline: 10px;
}

.item .actions-menu .rating {
  background-color: var(--yellow);
  width: fit-content;
  border-radius: 12px;
  padding: 0px 12px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.item .actions-menu .like-container {
  width: 50%;
  display: flex;
  padding-inline: 10px;
  justify-content: end;
}

.arrow {
  display: flex;
  align-items: center;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.shop-list .left {
  left: 10px;
  background-color: #dddddddd;
  padding: 5px;
  border-radius: 30px;
  border: 1px solid #0000004e;
}

.shop-list .right {
  right: 10px;
  background-color: #dddddddd;
  border-radius: 30px;
  padding: 5px;
  border: 1px solid #0000004e;
}

.item .salon-info {
  padding: 10px;
}

.item .salon-name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.item .salon-subheading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  line-height: 10px;
}

.item .view-shop {
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  cursor: pointer;
}

.item .view-shop:hover {
  transform: scale(1.01);
}

.item .view-shop:active {
  transform: scale(1.03);
}

.no-data {
  margin: auto;
  padding: 0px 10px;
  line-height: 250px;
  font-weight: 500;
  font-size: 20px;
  color: var(--grey2);
}

.shop-list .no-location {
  background-color: #f5f5f56f;
  margin: auto auto;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0px 10px;
  height: 282px;
  font-weight: 500;
  color: var(--grey2);
}