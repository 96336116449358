.search {
  min-height: 100vh;
}

.search .header {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  text-align: center;
}

.search .header .message {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}

.search .header .text-container {
  width: 50%;
  word-wrap: break-word;
}

.search hr {
  margin-inline: 10px;
  color: var(--grey2);
  opacity: 0.1;
}

.search .searchBar {
  color: black;
  width: 375px;
}

@media (max-width: 400px) {
  .search .searchBar {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .search .header {
    flex-direction: column;
  }
}

.search .main-body {
  display: flex;
  min-height: 800px;
  max-height: 1500px;
  padding: 20px 20px;
}

@media (max-width: 1080px) {
  .search .main-body {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .search .main-body .filters {
    width: 90%;
  }

  .search .main-body .list {
    width: 90%;
  }
}

.search .main-body .filters {
  width: 25%;
  background-color: var(--grey3);
  padding: 30px;
  gap: 25px;
  border-radius: 12px;
  height: max-content;
}

.search .main-body .list {
  width: 75%;
  margin-inline: 5%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.search .list .list-items {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 97%;
}

.search .list .sort {
  align-self: flex-end;
  padding: 20px;
}

@media (max-width: 1080px) {
  .search .main-body .filters {
    width: 35%;
  }

  .search .main-body .list {
    width: 65%;
  }
}

@media (max-width: 768px) {
  .search .main-body {
    flex-direction: column;
  }

  .search .main-body .filters {
    width: 100%;
  }

  .search .main-body .list {
    width: 100%;
  }
}

.search .filters .submit-button {
  width: 100%;
  background-color: var(--yellow);
  color: black;
  padding: 0px 16px 0px 16px;
  border-radius: 12px;
  min-width: 116px;
  font-family: Poppins, sans-serif !important;
  border: 0;
  cursor: pointer;
}

.search .submit-button:hover {
  width: 100%;
  background-color: var(--yellowHover) !important;
  color: black !important;
  transform: scale(1.02, 1.02);
}

.search .filter-items {
  display: flex;
  gap: 10px;
  margin-inline-start: 15px;
}

.search .filter-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
}

.search .list .item-card {
  display: flex;
  background-color: var(--grey3);
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 12px;
  line-height: 3px;
}

.search .list .body-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search .list .name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.search .list .name-container .heading {
  font-weight: 500;
  font-size: 18px;
}

.search .list .name-container .subheading {
  font-weight: 600;
  font-size: 14px;
}

.search .list .item-text {
  font-size: 14px;
  font-weight: 300;
}

.search .list .item-price {
  font-size: 14px;
  font-weight: 300;
  background-color: #e2d7626c;
  line-height: normal;
  padding: 7px;
  border-radius: 20px;
}

.search .list .number-container {
  display: flex;
  gap: 25px;
}

.search .list .values {
  display: flex;
  align-items: center;
  gap: 5px;
}

.search .list .service {
  background-color: white;
  border: 1px solid var(--grey2);
  border-radius: 30px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.search .list .service-button {
  background-color: #e26274;
  border-radius: 12px;
  align-self: center;
  cursor: pointer;
}

.search .list .service-button:hover {
  background-color: #cd3549;
  border: none;
  color: black;
}

.search .list .rating {
  background-color: var(--yellow);
  border-radius: 30px;
  padding: 0px 10px;
  line-height: 0px;
  height: fit-content;
  font-size: 12px;
}

.search .services {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.search .item-card img {
  object-fit: contain;
  mix-blend-mode: multiply;
}

.search .load-more-wrapper {
  padding: 25px;
}

.search .load-more-wrapper .ant-btn {
  background-color: var(--yellow);
  color: black;
  border: none;
  padding: 0px 16px 0px 16px;
  border-radius: 12px;
  min-width: 116px;
  font-family: Poppins, sans-serif !important;
}

.search .load-more-wrapper .ant-btn:hover {
  background-color: var(--yellowHover) !important;
  color: rgb(0, 0, 0) !important;
  transform: scale(1.02, 1.02);
}

.shop-info {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: fit-content;
  padding-inline: 10px;
  width: 100%;
}

.shop-info:hover {
  transform: scale(1.01);
}

@media screen and (max-width: 570px) {
  .search .list .shop-info {
    align-items: center;
    justify-content: center;
  }

  .search .list .name-container {
    flex-direction: column;
  }

  .search .list .values {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 450px) {
  .search .list .item-card {
    flex-direction: column;
  }
}