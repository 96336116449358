.discover {
  height: 100%;
}

.discover .heading {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
}

.discover hr {
  margin-inline: 10px;
  color: var(--grey2);
  opacity: 0.1;
}

.discover .body-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
  padding: 20px 0px;
}

.discover .body-container .filter-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.discover .searchBar {
  color: black;
  width: 375px;
}

@media (max-width: 400px) {
  .discover .searchBar {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .discover .heading {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .discover .filter-options {
    flex-direction: column;
  }
}

.body-container .shops-wrapper {
  width: 100%;
}